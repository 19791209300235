<template>
  <b-alert v-model="showDismissibleAlert" v-height-fade.appear variant="warning" class="mb-0 floating-alert">
    <div class="alert-body d-flex justify-content-center align-items-center">BETA V2.6</div>
  </b-alert>
</template>

<script>
import { BAlert } from "bootstrap-vue";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";
export default {
  name: "VersionAlert",
  components: {
    BAlert,
  },
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  data() {
    return {
      showDismissibleAlert: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.floating-alert {
  display: block;
  position: fixed;
  right: 85px;
  top: 30px;
//   width: 100%;
  z-index: 998;
}
</style>
