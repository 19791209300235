<template>
  <b-sidebar
    id="true"
    width="55%"
    :visible="sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('toggle_sidebar', val)"
  >
    <template>
      <b-card class="card-transaction" no-body>
        <b-card-header>
          <b-card-title>Listing Details</b-card-title>
          <b-button variant="flat-primary" class="float-right" size="sm" @click="() => $emit('toggle_sidebar', false)">
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">Close</span>
          </b-button>
        </b-card-header>
        <b-card-body v-if="permissions">
          <!-- Custom Listing Data Section Start-->
          <b-table-simple v-if="listing_manual_data && Object.keys(listing_manual_data).length" hover small caption-top responsive>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-center" colspan="2">Custom / Correction Data</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template>
                <b-tr v-for="(value, key) of listing_manual_data" :key="key">
                  <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>
                  <b-td class="text-left">{{ value }}</b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
          <!-- Custom Listing Data Section End-->
          <!-- Listing data Section -->
          <b-row>
            <b-col cols="12" md="8" class="pr-0">
              <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th class="text-center" colspan="2">Listing information</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="listing_data && Object.keys(listing_data).length">
                    <template v-for="key of Object.keys(listing_data)">
                      <b-tr :key="key" v-if="!exclude_fields.includes(key)">
                        <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>
                        <b-td class="text-left">
                          <div v-if="link_fields.includes(key)" @click="link_clicked(listing_data[key])">
                            <feather-icon icon="Link2Icon" />
                          </div>
                          <div v-else>
                            {{ format_values(key, listing_data[key]) }}
                          </div>
                        </b-td>
                      </b-tr>
                    </template>

                    <!-- <b-tr v-if="listing_data && listing_data.address">
                      <b-th class="capitalize-text">
                        Easement
                        <feather-icon
                          icon="InfoIcon"
                          v-b-tooltip.v-primary.title="'Toggle required information layers from left side panel in the opened tab'"
                        />
                      </b-th>
                      <b-td class="text-left">
                        <feather-icon
                          icon="Link2Icon"
                          class="mr-10"
                          @click="
                            link_clicked(
                              'https://spatial.information.qld.gov.au/arcgis/home/webmap/viewer.html?useExisting=2&layers=4acf9b96f66c4e3fa63739a6e71a81fb&find=' +
                                listing_data.address
                            )
                          "
                        />
                      </b-td>
                    </b-tr> -->

                    <!-- <b-tr v-if="listing_data && listing_data.address">
                      <b-th class="capitalize-text">Satellite Imagery & Easement</b-th>
                      <b-td class="text-left">
                        <b-link
                          :href="'https://www.arcgis.com/apps/webappviewer/index.html?id=0613d53beac44a088731063bf7fe64ee&find=' + listing_data.address"
                          target="_blank"
                          class="mr-10"
                        >
                          <feather-icon icon="Link2Icon" />
                        </b-link>
                      </b-td>
                    </b-tr> -->
                  </template>
                  <template v-else>
                    <b-tr>
                      <b-td class="text-center" colspan="2">No Listing Data Found</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col cols="12" md="4" class="pl-0">
              <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th class="text-center" colspan="1">Links</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="Object.keys(listing_data).length">
                    <!-- independent links -->
                    <b-tr v-if="listing_data && listing_data.listing_web_address">
                      <b-th class="link-th" @click="link_clicked(listing_data.listing_web_address)">
                        <feather-icon icon="Link2Icon" />
                        Source
                      </b-th>
                    </b-tr>

                    <b-tr v-if="listing_data && listing_data.address">
                      <b-th class="link-th" @click="link_clicked('https://www.google.com/search?q=' + listing_data.address)">
                        <feather-icon icon="SearchIcon" />
                        Search Google
                      </b-th>
                    </b-tr>

                    <!-- next link -->
                    <b-overlay
                      class="exclude-filters-overlay-background"
                      :show="!permissions.property_data.includes('council_information')"
                      variant="transparent"
                      opacity="0.7"
                      blur="2.5px"
                    >
                      <b-tr v-if="property_data && Object.keys(property_data).length && property_data.lotplan && listing.council == 'brisbane_council'">
                        <b-th class="link-th" @click="link_clicked(`https://cityplan.brisbane.qld.gov.au/eplan/property/${property_data.lotplan}/0/209?_t=property`)" disabled>
                          <feather-icon icon="Link2Icon" />
                          Council Lookup
                        </b-th>
                      </b-tr>
                    </b-overlay>

                    <!-- next link -->
                    <b-overlay
                      class="exclude-filters-overlay-background"
                      :show="!permissions.property_data.includes('development_approvals')"
                      variant="transparent"
                      opacity="0.7"
                      blur="2.5px"
                    >
                      <b-tr v-if="property_data && Object.keys(property_data).length && property_data.lot_id && listing.council == 'brisbane_council'">
                        <b-th
                          class="link-th"
                          @click="link_clicked('https://developmenti.brisbane.qld.gov.au/Home/FilterDirect?filters=LandNumber=' + property_data.lot_id)"
                        >
                          <feather-icon icon="Link2Icon" />
                          Developmenti
                        </b-th>
                      </b-tr>
                    </b-overlay>

                    <!-- water 7 sewerage link -->
                    <b-overlay
                      class="exclude-filters-overlay-background"
                      :show="!permissions.property_data.includes('water')"
                      variant="transparent"
                      opacity="0.7"
                      blur="2.5px"
                    >
                      <b-tr v-if="listing_data && listing_data.address && listing.council == 'brisbane_council'">
                        <b-th
                          class="link-th"
                          @click="
                            link_clicked(
                              'https://quu.maps.arcgis.com/apps/webappviewer/index.html?id=3d33e3db46894d46a9cffca08eae68b9&find=' + listing_data.address
                            )
                          "
                        >
                          <feather-icon icon="Link2Icon" />
                          Water & Sewer
                        </b-th>
                      </b-tr>
                    </b-overlay>

                    <b-overlay
                      class="exclude-filters-overlay-background"
                      :show="!permissions.property_data.includes('stormwater')"
                      variant="transparent"
                      opacity="0.7"
                      blur="2.5px"
                    >
                      <b-tr v-if="listing_data && listing_data.address && listing.council == 'brisbane_council'">
                        <b-th
                          class="link-th"
                          @click="
                            link_clicked('https://www.arcgis.com/apps/webappviewer/index.html?id=0613d53beac44a088731063bf7fe64ee&find=' + listing_data.address)
                          "
                        >
                          <feather-icon icon="Link2Icon" />
                          Stormwater
                        </b-th>
                      </b-tr>
                    </b-overlay>

                    <b-overlay
                      class="exclude-filters-overlay-background"
                      :show="!permissions.property_data.includes('electrical')"
                      variant="transparent"
                      opacity="0.7"
                      blur="2.5px"
                    >
                      <b-tr v-if="listing_data && listing_data.address">
                        <b-th
                          class="link-th"
                          @click="
                            link_clicked(
                              'https://ergon.maps.arcgis.com/apps/webappviewer/index.html?id=5a53f6f37db84158930f9909e4d30286&find=' + listing_data.address
                            )
                          "
                        >
                          <feather-icon icon="Link2Icon" />
                          Electrical
                        </b-th>
                      </b-tr>
                    </b-overlay>

                    <!-- <b-tr v-if="listing_data && listing_data.address">
                  <b-th class="capitalize-text">
                    Easement
                    <feather-icon icon="InfoIcon" v-b-tooltip.v-primary.title="'Toggle required information layers from left side panel in the opened tab'" />
                  </b-th>
                  <b-td class="text-left">
                    <feather-icon
                      icon="Link2Icon"
                      class="mr-10"
                      @click="
                        link_clicked(
                          'https://spatial.information.qld.gov.au/arcgis/home/webmap/viewer.html?useExisting=2&layers=4acf9b96f66c4e3fa63739a6e71a81fb&find=' +
                            listing_data.address
                        )
                      "
                    />
                  </b-td>
                </b-tr> -->

                    <!-- <b-tr v-if="listing_data && listing_data.address">
                  <b-th class="capitalize-text">Satellite Imagery & Easement</b-th>
                  <b-td class="text-left">
                    <b-link
                      :href="'https://www.arcgis.com/apps/webappviewer/index.html?id=0613d53beac44a088731063bf7fe64ee&find=' + listing_data.address"
                      target="_blank"
                      class="mr-10"
                    >
                      <feather-icon icon="Link2Icon" />
                    </b-link>
                  </b-td>
                </b-tr> -->
                  </template>
                  <template v-else>
                    <b-tr>
                      <b-td class="text-center" colspan="2">No Links Found</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>

          <!-- Map Section Start -->
          <b-row>
            <b-col cols="12">
              <iframe
                v-if="listing_data && listing_data.address"
                width="100%"
                height="250"
                style="border: 0"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyAVxI1NtkgyDjG9RniT8Mymq63x4E7jdi4&q=' + encodeURIComponent(listing_data.address)"
              ></iframe>
            </b-col>
          </b-row>
          <!-- Map Section End -->

          <!-- Portal Check Section -->
          <template v-if="is_portal_section_allowed()">
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th class="text-center" colspan="2">Portal Check</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="domain_data && Object.keys(domain_data).length && domain_data['id']">
                  <template v-for="(value, key) of domain_data">
                    <b-tr v-if="domain_include_fields.includes(key)" :key="key">
                      <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>

                      <b-td v-if="type(value) == 'object'" class="text-left">{{ value.join(", ") }}</b-td>
                      <b-td v-else class="text-left">{{ value }}</b-td>
                    </b-tr>
                  </template>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td class="text-center" colspan="2">No Portal Data Found</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </template>

          <!-- Google Check Section -->
          <b-table-simple hover small caption-top responsive>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-center" colspan="2">AI Market Check</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="google_data && Object.keys(google_data).length && google_data['last_checked']">
                <b-tr v-for="(value, key) of google_data" :key="key">
                  <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>
                  <b-td class="text-left">
                    <div v-if="link_fields.includes(key) && value" @click="link_clicked(value)">
                      <feather-icon icon="Link2Icon" />
                    </div>
                    <div v-else>{{ format_values(key, value) }}</div>
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <b-tr>
                  <b-td class="text-center" colspan="2">No Google Data Found</b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>

          <!-- Property information table -->
          <b-overlay
            class="exclude-filters-overlay-background"
            :show="!permissions.sidebar_sections.includes('council_information')"
            variant="transparent"
            opacity="1.0"
            blur="2.5px"
          >
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th class="text-center" colspan="2">Property Information</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="Object.keys(property_data).length">
                  <template v-for="(value, key) of property_data">
                    <template v-if="!exclude_fields.includes(key)">
                      <!-- if array -->
                      <template v-if="Array.isArray(value)">
                        <b-tr v-for="(v, i) of value" :key="'f' + i">
                          <b-th class="capitalize-text">{{ map_fields(key) + " " + (i + 1) }}</b-th>
                          <b-td class="text-left">{{ format_values(key, v) }}</b-td>
                        </b-tr>
                      </template>
                      <!-- if not array -->
                      <template v-else>
                        <b-tr :key="key">
                          <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>
                          <b-td class="text-left">{{ format_values(key, value) }}</b-td>
                        </b-tr>
                      </template>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td class="text-center" colspan="2">No Property Data Found</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-overlay>

          <!-- Contact information table -->
          <template v-if="is_contact_section_allowed()">
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th class="text-center" colspan="2">Contact Information</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="Object.keys(contact_data).length">
                  <template v-for="(agent, index) of contact_data.agents">
                    <b-tr v-if="index" :key="'div' + index" variant="primary">
                      <b-td style="border: 0px !important" colspan="2"></b-td>
                    </b-tr>
                    <template v-for="(value, key) of agent">
                      <b-tr v-if="!exclude_fields.includes(key)" :key="key + index">
                        <b-th class="capitalize-text">{{ key }}</b-th>
                        <b-td class="text-left">{{ value }}</b-td>
                      </b-tr>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td class="text-center" colspan="2">No Contact Information Found For This Listing</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </template>

          <!-- Flags table -->
          <b-overlay
            class="exclude-filters-overlay-background"
            :show="!permissions.sidebar_sections.includes('council_flags')"
            :variant="'transparent'"
            :opacity="1.0"
            :blur="'2px'"
            rounded="sm"
            :spinner-variant="null"
          >
            <b-table-simple hover small caption-top responsive width="100%">
              <b-thead head-variant="dark">
                <!-- <b-tr>
                <b-th class="text-center" colspan="3">Property Flags</b-th>
              </b-tr> -->
                <b-tr>
                  <b-th class="text-left w-40">Flag Group</b-th>
                  <b-th class="text-left w-30">
                    Flag
                    <span class="small-text" style="margin-left: 2px; margin-bottom: 4px">(Hover for details)</span>
                  </b-th>
                  <b-th class="text-center w-30">
                    <span>Plan Reference</span>
                    <feather-icon icon="ExternalLinkIcon" small style="margin-left: 2px; margin-bottom: 4px" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="flags_data.length">
                  <template v-for="(flag, index) of flags_data">
                    <template v-if="!exclude_flags.includes(flag.flag)">
                      <b-tr :key="'tfaa' + index">
                        <b-td class="text-left">{{ flag["flagType"] }}</b-td>
                        <b-td class="text-left">
                          <div
                            v-b-tooltip:hover.viewport="{
                              title: '<div>Code: ' + flag['flag'] + '</div>' + flag['reportDescription'],
                              html: true,
                              trigger: 'hover',
                              placement: 'top',
                              variant: 'primary',
                              delay: 100,
                              customClass: 'flags-tooltip-container',
                            }"
                          >
                            <!-- v-b-tooltip:hover.viewport.d500.top.v-primary.title.html="'<div>Title: ' + flag['ReportTitle'] + '</div><br>' + flag['ReportDescription']"> -->
                            <span style="font-size: 0.9em">{{ flag["reportTitle"] }}</span>
                            <!-- <span class="px-1">|</span>
                          <span>{{ flag["ReportTitle"] }}</span> -->
                          </div>
                        </b-td>
                        <b-td class="text-center">
                          <b-link href="https://cityplan.brisbane.qld.gov.au/eplan/#Rules/0/0/0/0/0" target="_blank">{{ flag["PlanSection"] }}</b-link>
                        </b-td>
                      </b-tr>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td class="text-center" colspan="3">{{ flags_request ? "Loading Flag Data..." : "No Flags Found For This Property" }}</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
        </b-card-body>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import moment from "moment";
import {
  BSidebar,
  BCard,
  BBadge,
  BCardBody,
  BButton,
  BCardTitle,
  BCardHeader,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BCol,
  BTfoot,
  BLink,
  VBTooltip,
  BRow,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BLink,
    BTbody,
    BTfoot,
    BThead,
    BTr,
    BTh,
    BTd,
    BCol,
    BSidebar,
    BCard,
    BBadge,
    BCardBody,
    BButton,
    BCardTitle,
    BCardHeader,
    BTableSimple,
    ToastificationContent,
    VBTooltip,
    BRow,
    BOverlay,
  },
  props: ["listing", "sidebar", "page"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      exclude_fields: [
        "listing_id",
        "property",
        "lotplan",
        "role",
        "council",
        "domain_data",
        "hide",
        "favourite",
        "hidden",
        "frontages",
        "viewed",
        "pid",
        "premarks",
        "listing_web_address",
        "favourite",
        "hidden",
        "viewed",
        "lat",
        "lon",
      ],
      domain_include_fields: [
        "status",
        // "last_updated",
        "last_checked",
        "portal",
        // "zone",
        // "address",
        // "created",
        // "areaSize",
        // "features",
        // "isResidential",
        // "onMarketTypes",
        // "propertyCategory",
      ],
      link_fields: ["listing_web_address", "website", "rea", "domain"],
      column_mapping: {
        status_date: "Last Update",
        property_type_other: "Category",
        listing_web_address: "Source",
        area: "Area (SQM)",
        frontages: "frontage",
        rea: "REA",
      },
      value_formatters: {
        area: (v) => (v ? v.toFixed(1) : v),
        max_frontage: (v) => (v ? v.toFixed(1) : v),
        fall: (v) => parseFloat(v).toFixed(1),
        frontages: (v) => (v ? v.toFixed(1) : v),
        zone: (v) => (v ? v.replace("QPP-", "") : v),
        time_on_market: (v) => (v ? moment().diff(moment(v, "YYYY-MM-DD"), "days") + " days" : v),
      },

      // tables data
      contact_section_fields: ["agents"],
      property_section_fields: ["property"],
      domain_section_fields: ["domain_data"],
      google_section_fields: ["google_data"],
      manual_section_fields: ["manual_data"],
      // contact_section_fields: ["agents"],
      // contact_section_fields: ["agents"],

      listing_data: {},
      property_data: {},
      contact_data: {},
      flags_data: [],
      domain_data: {},
      google_data: {},
      listing_manual_data: {},

      // flags daa fields
      exclude_flags: ["Grid"],
      exclude_flag_fields: [],
      flag_tooltip: ["ReportDescription"],
      flags_request: false,

      exclude_portal_section: ["user_search_and_add"],
      exclude_contact_section: ["user_search_and_add"],
      permissions: null,
    };
  },
  watch: {
    listing: {
      deep: true,
      async handler(newval) {
        // console.log("Sidebar Listing: ", newval);
        if (!newval || !Object.keys(newval).length) return;
        this.set_defaults();
        for (let [key, value] of Object.entries(newval)) {
          if (this.google_section_fields.includes(key)) this.google_data = value;
          else if (this.manual_section_fields.includes(key)) this.listing_manual_data = value;
          else if (this.domain_section_fields.includes(key)) this.domain_data = value;
          else if (this.contact_section_fields.includes(key)) this.contact_data[key] = this.json_to_array(value);
          else if (this.property_section_fields.includes(key) && value && value.length) this.property_data = value[0];
          else this.listing_data[key] = value;
        }
        this.get_flags();
      },
    },
  },
  async mounted() {
    this.permissions = await this.$store.dispatch("get_permissions");
  },
  methods: {
    async get_flags() {
      try {
        if (!this.listing || !this.listing.property.length || !this.listing.property[0].lotplan) return;
        let listing = {};
        listing.lotplan = this.listing.property[0].lotplan;
        this.flags_request = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getlistingflags`, { listing });
        // console.log(res.data);
        this.flags_request = false;

        if (!res.data.success) throw new Error("Flags could not be fetched");
        this.flags_data = res.data.flags;
        console.log(this.flags_data)
      } catch (error) {
        this.flags_request = false;
        console.log(error);
        this.showToast("danger", "Error", "Error while trying to fetch flags");
      }
    },
    json_to_array(v) {
      try {
        v = JSON.parse(v);
        return Array.isArray(v) ? v : [v];
      } catch (error) {
        return [];
      }
    },
    set_defaults() {
      this.listing_data = {};
      this.property_data = {};
      this.flags_data = {};
      this.contact_data = {};
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    map_fields(key) {
      if (Object.keys(this.column_mapping).includes(key)) key = this.column_mapping[key];
      return key;
    },
    format_values(key, value) {
      if (Object.keys(this.value_formatters).includes(key)) value = this.value_formatters[key](value);
      return value;
    },
    link_clicked(value) {
      if (!value) return;
      value = value.includes("http") ? value : "https://" + value;
      window.open(value);
    },
    type(v) {
      return typeof v;
    },
    sanitize_url(url) {
      return url.replace();
    },
    is_portal_section_allowed() {
      return !this.exclude_portal_section.includes(this.page);
    },
    is_contact_section_allowed() {
      return !this.exclude_contact_section.includes(this.page);
    },
  },
  filters: {
    format_fields(str) {
      return typeof str == "string"
        ? str
            .replace(/_/g, " ")
            .replace(/([A-Z]{1}[a-z]+)/g, " $1")
            .trim()
        : str;
    },
    formatDate: function (date) {
      return moment(date).fromNow();
    },
  },
};
</script>

<style lang="scss">
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.capitalize-text {
  text-transform: capitalize;
}
.flags-tooltip-container.tooltip .tooltip-inner {
  max-width: 500px !important;
  width: 400px !important;
}
.small-text {
  font-size: 10px;
}
.width-30 {
  width: 30%;
}
.width-70 {
  width: 70%;
}
.w-160p {
  width: 160px;
}
.w-33 {
  width: 33%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}
.listing_table_fields {
  border-bottom: 1px solid rgb(224, 221, 221);
}
.link-th {
  line-height: 20px;
}
.link-th svg {
  margin-right: 5px;
  color: rgb(87, 87, 177);
}
</style>
