<template>
  <b-sidebar
    id="true"
    width="50%"
    :visible="sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('toggle_sidebar', val)"
  >
    <template>
      <b-card class="card-transaction" no-body>
        <b-card-header class="d-flex justify-content-between align-items-center">
          <b-card-title>Manage Filters</b-card-title>
          <div>
            <b-button variant="flat-primary" @click="initialize()">
              <feather-icon icon="RefreshCwIcon" class="mr-25" />
              <span class="align-middle">Refresh</span>
            </b-button>
            <b-button variant="flat-primary" @click="() => $emit('toggle_sidebar', false)">
              <feather-icon icon="XIcon" class="mr-25" />
              <span class="align-middle">Close</span>
            </b-button>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row class="d-flex justify-content-start align-items-end">
            <b-col cols="12" md="12" class="mb-1">
              <label>Action</label>
              <v-select
                v-model="filter_action"
                :options="filter_actions"
                label="text"
                :reduce="(v) => v.value"
                class="w-100"
                :placeholder="'Select Action'"
                :clearable="false"
              />
            </b-col>
            <template v-if="filter_action">
              <b-col cols="12" md="12" class="mb-1" v-if="['update', 'set'].includes(filter_action)">
                <label>Choose Filter</label>
                <v-select
                  v-model="filter.id"
                  :options="saved_filters"
                  label="name"
                  :reduce="(v) => v.id"
                  class="w-100"
                  placeholder="Select Filter To Update"
                  :clearable="false"
                />
              </b-col>
              <b-col cols="12" md="12" class="mb-1" v-if="['update', 'save'].includes(filter_action)">
                <label>Filter Name</label>
                <b-form-input v-model="filter.name" placeholder="Enter Filter Name" class="w-100"></b-form-input>
              </b-col>
              <b-col cols="12" md="12" class="mb-1" v-if="['update', 'save'].includes(filter_action)">
                <label>Filter Value</label>
                <b-form-input placeholder="Current Filter Value" class="w-100" disabled></b-form-input>
              </b-col>
              <b-col cols="12" md="12" class="mb-1" v-if="['update', 'save'].includes(filter_action)">
                <b-form-checkbox v-model="filter.notifications" class="mb-1" disabled>Notifications <b-badge variant="light-warning" class="ml-1">Coming Soon</b-badge></b-form-checkbox>
                <b-form-checkbox v-model="filter.email_notifications" disabled>
                  Email Notifications
                  <b-badge variant="light-warning" class="ml-1">Coming Soon</b-badge>
                </b-form-checkbox>
              </b-col>
              <b-col cols="12" md="12" class="mt-4 d-flex align-items-center justify-content-end">
                <div>
                  <b-button v-if="['set'].includes(filter_action)" variant="flat-primary" class="mr-50" @click="set_filter()">
                    <feather-icon icon="CheckIcon" class="mr-25" />
                    Set As Active
                  </b-button>
                  <b-button
                    v-if="['update', 'save'].includes(filter_action)"
                    class="mr-50 capitalize-text"
                    variant="flat-success"
                    @click="save_filters()"
                    :disabled="(filter_action == 'update' && !filter.id) || request_sent"
                  >
                    <feather-icon v-if="!request_sent" icon="SaveIcon" class="mr-25" />
                    <b-spinner v-if="request_sent" small type="grow" class="mr-50"></b-spinner>
                    {{ filter_action }}
                  </b-button>

                  <!-- Delete Button -->
                  <b-button v-if="['update'].includes(filter_action)" variant="flat-danger" @click="delete_filters()" :disabled="!filter.id">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span class="align-middle">Delete</span>
                  </b-button>
                </div>
              </b-col>
            </template>
          </b-row>
        </b-card-body>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BCard,
  BBadge,
  BCardBody,
  BButton,
  BCardTitle,
  BCardHeader,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BTfoot,
  BLink,
  VBTooltip,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  name: "FilterManagementSidebar",
  components: {
    BLink,
    BTbody,
    BTfoot,
    BThead,
    BTr,
    BTh,
    BTd,
    BSidebar,
    BCard,
    BBadge,
    BCardBody,
    BButton,
    BCardTitle,
    BCardHeader,
    BTableSimple,
    ToastificationContent,
    VBTooltip,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BSpinner,
  },
  props: ["filters", "not_filters", "sidebar", "page", "sanitize_filters"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      saved_filters: [],
      filter_actions: [
        { text: "Save Filter", value: "save" },
        { text: "Update Filter", value: "update" },
        { text: "Load Filter", value: "set" },
      ],
      filter_action: "set",
      filter: {
        id: 0,
        name: "",
        value: [],
        notifications: true,
        email_notifications: false,
      },
      default_filter: {
        id: 0,
        name: "",
        value: [],
        notifications: true,
        email_notifications: false,
      },
      request_sent: false,
    };
  },
  watch: {
    saved_filters: {
      deep: true,
      handler(newval) {
        this.$emit("saved-filters-changed", newval);
      },
    },
    "filter.id"(newval) {
      console.log(newval);
      this.set_sidebar_filter(newval);
      console.log(this.filter);
    },
    // async sidebar(show) {
    //   if (show) await this.initialize();
    // },
  },
  async mounted() {
    try {
      this.setDefaults();
      await this.initialize();
    } catch (error) {}
  },
  methods: {
    async initialize() {
      try {
        await this.get_saved_filters();
      } catch (error) {
        this.showToast("danger", "Error", "Unable to get filer data");
      }
    },
    async get_saved_filters() {
      try {
        if (this.request_sent) return;

        this.request_sent = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getfilters`, { page: this.page });
        this.request_sent = false;

        if (!res.data.success) throw new Error("Flags could not be fetched");
        this.saved_filters = res.data.filters;
        console.log(this.saved_filters);
        // this.showToast("success", "Info", "Filter data updated");
      } catch (error) {
        this.request_sent = false;
        console.log(error);
        this.showToast("danger", "Error", "Error while trying to fetch flags");
      }
    },
    async save_filters() {
      try {
        if (this.request_sent) return;

        // sanitizing filters
        let f = this.sanitize_filters(this.filters);
        let nf = this.sanitize_filters(this.not_filters);
        // let f = this.filters;
        // let nf = this.not_filters;

        // validating fields
        if (!this.filter.name) {
          return this.showToast("danger", "Error", "Filter must have a valid name");
        }

        if (!f.length && !nf.length) {
          return this.showToast("danger", "Error", "Atleast 1 filter should be selected");
        }

        // setting values
        let newfilter = this.filter_action == "save" ? true : false;

        let filter = Object.assign({}, this.filter);
        filter.page = this.page;
        let value = {};
        value.filters = Object.assign([], f);
        value.not_filters = Object.assign([], nf);
        filter.value = value;

        // console.log(filter);
        // sending request
        this.request_sent = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/saveorupdatefilters`, { newfilter, filter });
        console.log(res.data);
        this.request_sent = false;

        if (!res.data.success) throw new Error(res.data.message);

        if (newfilter) {
          this.saved_filters.push(res.data.filter);
        } else {
          let index = this.saved_filters.findIndex((sf) => sf.id == filter.id);
          if (index > -1) {
            this.saved_filters[index]["id"] = filter.id;
            this.saved_filters[index]["name"] = filter.name;
            this.saved_filters[index]["value"] = { filters: [], not_filters: [] };
            for (let v of filter.value.filters) this.saved_filters[index].value.filters.push(Object.assign({}, v));
            for (let v of filter.value.not_filters) this.saved_filters[index].value.not_filters.push(Object.assign({}, v));
          } else {
          }
        }
        this.showToast("success", "Success", "Filters updated");
      } catch (error) {
        this.request_sent = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Filters could not be updated");
      }
    },
    async delete_filters() {
      try {
        if (this.request_sent) return;

        this.request_sent = true;
        let id = this.filter.id;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/deletefilters`, { id });
        this.request_sent = false;

        if (!res.data.success) throw new Error(res.data.message);

        let index = this.saved_filters.findIndex((sf) => sf.id == id);
        this.$delete(this.saved_filters, index);
        this.filter = Object.assign({}, this.default_filter);
        this.showToast("success", "Success", "Filter removed successfully");
      } catch (error) {
        this.request_sent = false;
        console.log(error);
        this.showToast("danger", "Error", "Filter could not be removed");
      }
    },
    set_sidebar_filter(id) {
      try {
        if (!id) return;
        let index = this.saved_filters.findIndex((f) => f.id == id);
        if (index == -1) throw new Error("No filter found for this id");
        let v = [];
        let nf = [];
        for (let f of this.saved_filters[index].value.filters) v.push(Object.assign({}, f));
        for (let f of this.saved_filters[index].value.not_filters) nf.push(Object.assign({}, f));

        this.filter = Object.assign({}, this.saved_filters[index]);
        this.filter.value = { filters: v, not_filters: nf };
      } catch (error) {
        console.log(error);
        this.showToast("danger", "Error", "Filter could not be set");
      }
    },
    async set_filter() {
      try {
        if (!this.filter.id) return;
        let id = this.filter.id;
        let index = this.saved_filters.findIndex((f) => f.id == id);
        if (index == -1) return;
        let v = [];
        let nf = [];
        for (let f of this.saved_filters[index].value.filters) v.push(Object.assign({}, f));
        for (let f of this.saved_filters[index].value.not_filters) nf.push(Object.assign({}, f));

        this.$emit("set_filter_values", { filters: v, not_filters: nf });
        this.$emit("toggle_sidebar", false);
      } catch (error) {
        this.showToast("danger", "Error", "Filter could not be set as active");
      }
    },
    setDefaults() {
      this.filter_action = "set";
      this.filter = {
        id: 0,
        name: "",
        value: [],
        notifications: true,
        email_notifications: false,
      };
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
  },
  filters: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.capitalize-text {
  text-transform: capitalize;
}
.small-text {
  font-size: 10px;
}
.width-30 {
  width: 30%;
}
.width-70 {
  width: 70%;
}
.w-160p {
  width: 160px;
}
</style>
