<template>
  <div>
    <b-button v-if="features.length" @click="sidebar = true" variant="outline-warning" class="btn-icon rounded-circle floating-button">
      <feather-icon icon="ThumbsUpIcon" />
    </b-button>

    <!-- visible sidebar -->
    <b-sidebar
      id="true"
      width="600px"
      :visible="sidebar"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(v) => (sidebar = v)"
    >
      <template>
        <b-card class="card-transaction" no-body>
          <b-card-header class="d-flex justify-content-between align-items-center">
            <b-card-title class="text-capitalize">Upcoming Features!!</b-card-title>
            <div>
              <b-button variant="flat-primary" @click="sidebar = false">
                <feather-icon icon="XIcon" class="mr-25" />
                <span class="align-middle">Close</span>
              </b-button>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row class="d-flex justify-content-start align-items-end">
              <!-- name -->
              <b-col cols="12" md="12" class="mb-1">
                <app-collapse v-if="features.length" accordion type="border">
                  <app-collapse-item v-for="(f, i) in features" :key="i" :title="f.name">
                    <div class="mb-4">{{ f.description }}</div>
                    <div class="d-flex justify-content-around align-items-center">
                      <b-button variant="flat-primary" @click="react(f.id, true)">
                        <feather-icon icon="ThumbsUpIcon" class="mr-25" />
                        <span class="align-middle text-capitalize">Yes, count me in on this</span>
                      </b-button>
                      <b-button variant="flat-warning" @click="react(f.id, false)">
                        <feather-icon icon="ThumbsDownIcon" class="mr-25" />
                        <span class="align-middle text-capitalize">No, I'll probably pass</span>
                      </b-button>
                    </div>
                  </app-collapse-item>
                </app-collapse>
                <div v-else class="d-flex justify-content-center align-items-center">No new feature found</div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, BCard, BCardBody, BButton, BCardTitle, BCardHeader, BRow, BCol, BListGroup, BListGroupItem } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "UpcomingFeaturesUserSidebar",
  components: {
    BSidebar,
    BCard,
    BCardBody,
    BButton,
    BCardTitle,
    BCardHeader,
    ToastificationContent,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      sidebar: false,
      features: [],
      requestSent: false,
    };
  },
  watch: {
    async sidebar(show) {
      if (show) await this.initialize();
      // else this.features = [];
    },
  },
  async mounted() {
    try {
      this.initialize();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initialize() {
      try {
        this.load_features();
      } catch (error) {
        console.log(error);
      }
    },
    async load_features() {
      try {
        this.requestSent = true;
        let res = await this.$axios.get(`${this.$API_BASE_FEATURES}/getuserfeatures`);
        this.requestSent = false;
        if (!res.data.success) throw new Error("features could not be fetched");
        this.features = res.data.features;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
      }
    },
    async react(id, liked) {
      try {
        if (!id) return;
        this.requestSent = true;
        let res = await this.$axios.post(`${this.$API_BASE_FEATURES}/reaction`, { id, liked });
        this.requestSent = false;
        if (!res.data.success) throw new Error("Reaction could not be updated");

        let index = this.features.findIndex((f) => f.id == id);
        this.features.splice(index, 1);
        return this.showToast("success", "Success", "Reaction Updated");
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        return this.showToast("success", "Success", error.message ? error.message : "Reaction could not be noted");
      }
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.floating-button {
  display: block;
  position: fixed;
  right: 40px;
  top: 30px;
  z-index: 998;
}
</style>
