<template>
  <div>
    <version-alert />
    <!-- upcoming features sidebar -->
    <upcoming-features-user-sidebar />
    <basic-filters-sidebar
      :page="page_name"
      :sidebar="sidebar"
      :filters="filters"
      :not_filters="not_filters"
      :sanitize_filters="sanitize_filters"
      @toggle_sidebar="(val) => (sidebar = val)"
      @set_filter_values="set_filter_values"
      @saved-filters-changed="(sf) => (saved_filters = sf)"
    />
    <b-card no-body style="min-height: 1px !important" v-if="permissions">
      <b-card-header class="pb-0">
        <b-row style="width: 100%">
          <b-col cols="12" class="d-flex align-items-baseline justify-content-between">
            <h5 class="mb-1 pb-1">Filters</h5>
            <div>
              <b-button v-if="parent_hide" variant="flat-primary" @click="local_hide = !local_hide" class="mb-1">
                <feather-icon icon="FilterIcon" class="mr-25" />
                <span class="align-middle">{{ hide_filters ? "Show" : "Hide" }} Filters</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body v-if="!hide_filters">
        <b-row class="d-flex justify-content-start align-items-end">
          <!-- filters -->
          <template v-if="filter_data && Object.keys(filter_data).length">
            <template v-for="(input, index) of filters">
              <template v-if="allowed_filters.includes(input.column)">
                <!-- boolean values: checkbox -->
                <b-col v-if="['check'].includes(column_definitions[input.column].input)" cols="12" md="3" class="mb-50" :key="index">
                  <!-- <label>{{ column_definitions[input.column].label }}</label> -->
                  <b-form-checkbox
                    v-model="input.value"
                    @input="(v) => (input.value = !v ? null : v)"
                    class="m-0 my-1"
                    :disabled="!permissions.filters.includes(input.column)"
                  >
                    {{ column_definitions[input.column].label }}
                  </b-form-checkbox>
                </b-col>

                <!-- presaved values: select dropdown -->
                <b-col v-else-if="['select'].includes(column_definitions[input.column].input)" cols="12" md="3" class="mb-50" :key="index">
                  <label>{{ column_definitions[input.column].label }}</label>
                  <v-select
                    v-model="input.value"
                    :options="filter_data[column_definitions[input.column].fdata]"
                    :label="column_definitions[input.column].fdata_field"
                    :reduce="
                      (v) =>
                        column_definitions[input.column].fdata_value
                          ? v[column_definitions[input.column].fdata_value]
                          : v[column_definitions[input.column].fdata_field]
                    "
                    class="w-100 capitalize-text"
                    :placeholder="'Select ' + column_definitions[input.column].label"
                    :multiple="column_definitions[input.column].multiple"
                    :disabled="!permissions.filters.includes(input.column)"
                  />
                </b-col>

                <!-- contains filters:; text fields -->
                <template v-else-if="['text'].includes(column_definitions[input.column].input)">
                  <b-col cols="12" md="3" class="mb-50" :key="index">
                    <label>{{ column_definitions[input.column].label }}</label>
                    <b-form-input
                      v-model="input.value"
                      :placeholder="'Enter ' + column_definitions[input.column].label"
                      @keyup.enter="search()"
                      class="w-100"
                      :disabled="column_definitions[input.column].disabled || !permissions.filters.includes(input.column)"
                    ></b-form-input>
                  </b-col>
                </template>

                <!-- range filters: 2x text fields -->
                <template v-else-if="['range'].includes(column_definitions[input.column].input)">
                  <b-col cols="12" md="3" class="mb-50" :key="index">
                    <label>{{ column_definitions[input.column].label }}</label>
                    <div class="d-flex">
                      <b-form-input
                        v-model="input.value[0]"
                        type="number"
                        @keyup.enter="search()"
                        :disabled="column_definitions[input.column].disabled || !permissions.filters.includes(input.column)"
                        placeholder="From"
                        class="mr-0"
                      ></b-form-input>
                      <b-form-input
                        v-model="input.value[1]"
                        type="number"
                        @keyup.enter="search()"
                        :disabled="column_definitions[input.column].disabled || !permissions.filters.includes(input.column)"
                        placeholder="To"
                        class="ml-1"
                      ></b-form-input>
                    </div>
                  </b-col>
                </template>
              </template>
            </template>
          </template>
        </b-row>

        <!-- Exclusion filters start-->
        <b-overlay
          id="exclude-filters-overlay-background"
          :show="!permissions.filters.includes('exclusion_filters')"
          :variant="'transparent'"
          :opacity="0.85"
          :blur="'2px'"
          rounded="sm"
          :spinner-variant="null"
        >
          <b-row v-if="show_not_filters && filter_data && Object.keys(filter_data).length" class="d-flex justify-content-start align-items-end">
            <b-col cols="12" class="py-1 mt-1 top-border">Exclusion Filters</b-col>
            <template v-for="(input, index) of not_filters">
              <template v-if="allowed_filters.includes(input.column)">
                <!-- boolean values: checkbox -->
                <b-col v-if="['check'].includes(column_definitions[input.column].input)" cols="12" md="3" class="mb-50" :key="index">
                  <!-- <label>{{ column_definitions[input.column].label }}</label> -->
                  <b-form-checkbox
                    v-model="input.value"
                    @input="(v) => (input.value = !v ? null : v)"
                    class="m-0 my-1"
                    :disabled="!permissions.filters.includes(input.column)"
                  >
                    {{ column_definitions[input.column].label }}
                  </b-form-checkbox>
                </b-col>

                <!-- presaved values -->
                <b-col v-else-if="['select'].includes(column_definitions[input.column].input)" cols="12" md="3" class="mb-50" :key="index">
                  <label>{{ column_definitions[input.column].label }}</label>
                  <v-select
                    v-model="input.value"
                    :options="filter_data[column_definitions[input.column].fdata]"
                    :label="column_definitions[input.column].fdata_field"
                    :reduce="
                      (v) =>
                        column_definitions[input.column].fdata_value
                          ? v[column_definitions[input.column].fdata_value]
                          : v[column_definitions[input.column].fdata_field]
                    "
                    class="w-100 capitalize-text"
                    :placeholder="'Select ' + column_definitions[input.column].label"
                    :multiple="column_definitions[input.column].multiple"
                    :disabled="!permissions.filters.includes(input.column) || !permissions.filters.includes(input.column)"
                  />
                </b-col>

                <!-- contains filters:; text fields -->
                <template v-else-if="['text'].includes(column_definitions[input.column].input)">
                  <b-col cols="12" md="3" class="mb-50" :key="index">
                    <label>{{ column_definitions[input.column].label }}</label>
                    <b-form-input
                      v-model="input.value"
                      :placeholder="'Enter ' + column_definitions[input.column].label"
                      @keyup.enter="search()"
                      class="w-100"
                      :disabled="column_definitions[input.column].disabled || !permissions.filters.includes(input.column)"
                    ></b-form-input>
                  </b-col>
                </template>

                <!-- range filters: 2x text fields -->
                <template v-else-if="['range'].includes(column_definitions[input.column].input)">
                  <b-col cols="12" md="3" class="mb-50" :key="index">
                    <label>{{ column_definitions[input.column].label }}</label>
                    <div class="d-flex">
                      <b-form-input
                        v-model="input.value[0]"
                        type="number"
                        @keyup.enter="search()"
                        :disabled="column_definitions[input.column].disabled || !permissions.filters.includes(input.column)"
                        placeholder="From"
                        class="mr-0"
                      ></b-form-input>
                      <b-form-input
                        v-model="input.value[1]"
                        type="number"
                        @keyup.enter="search()"
                        :disabled="column_definitions[input.column].disabled || !permissions.filters.includes(input.column)"
                        placeholder="To"
                        class="ml-1"
                      ></b-form-input>
                    </div>
                  </b-col>
                </template>
              </template>
            </template>
          </b-row>
        </b-overlay>
        <!-- Exclusion filters end-->

        <!-- button row start -->
        <b-row class="d-flex align-items-baseline justify-content-right">
          <b-col cols="12" class="d-flex align-items-baseline justify-content-between mt-4">
            <span class="text-muted">{{ selected_filter_length + selected_not_filter_length }} filters selected</span>

            <div class="d-flex align-items-baseline justify-content-end">
              <b-button variant="flat-primary" @click="search()">
                <feather-icon icon="SearchIcon" class="mr-25" />
                <span class="align-middle">Search</span>
              </b-button>

              <b-button variant="flat-danger" @click="clear()">
                <feather-icon icon="XIcon" class="mr-25" />
                <span class="align-middle">Clear</span>
              </b-button>

              <b-button variant="flat-primary" @click="show_not_filters = !show_not_filters">
                <feather-icon icon="FilterIcon" class="mr-25" />
                <span class="align-middle">{{ show_not_filters ? "Hide" : "Show" }} Advance</span>
              </b-button>

              <b-button variant="flat-primary" @click="sidebar = true">
                <feather-icon icon="FilterIcon" class="mr-25" />
                <span class="align-middle">Filters</span>
              </b-button>
              <v-select
                v-model="selected_filter"
                :options="saved_filters"
                @option:selected="set_filter_values_select"
                label="name"
                class="capitalize-text load_filter_select"
                placeholder="Load Filter"
                :multiple="false"
                style="width: 200px"
              >
                <template #selected-option="{ name }">
                  <div style="display: flex; align-items: baseline" class="load-filters-selected">
                    {{ name }}
                  </div>
                </template>
              </v-select>

              <!-- <b-button variant="flat-primary" @click="refresh_filters()">
                <feather-icon icon="RefreshCwIcon" class="mr-25" />
                <span class="align-middle">Refresh</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
        <!-- button row end -->
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormSelect, BFormInput, BBadge, BFormCheckbox, BOverlay } from "bootstrap-vue";
import vSelect from "vue-select";
import { BFormDatepicker } from "bootstrap-vue";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BasicFiltersSidebar from "./BasicFiltersSidebar.vue";
import UpcomingFeaturesUserSidebar from "../../features/components/UpcomingFeaturesUserSidebar.vue";
import VersionAlert from "./VersionAlert.vue";

export default {
  components: {
    BBadge,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormDatepicker,
    ToastificationContent,
    BasicFiltersSidebar,
    UpcomingFeaturesUserSidebar,
    VersionAlert,
    BFormCheckbox,
    BOverlay,
  },

  props: ["allowed_filters", "suburb_set_to_use", "page", "parent_hide"],
  data() {
    return {
      page_name: this.page,
      local_storage_keyname: "listings_filter_data",
      filter_data: null,
      // column definitions for filters
      column_definitions: {
        address: { input: "text", label: "Address", presaved: false },
        street: { input: "text", label: "Street", presaved: false },
        suburb: { multiple: true, input: "select", label: "Suburbs", presaved: true, fdata: "qld_suburbs", fdata_field: "suburb" },
        postal_code: { input: "select", label: "Postcodes", presaved: true, fdata: "bcc_postcodes", fdata_field: "postcode", multiple: true },
        state: { input: "select", label: "State", presaved: true, fdata: "states", fdata_field: "state", disabled: false, multiple: true },
        council: { multiple: true, input: "select", label: "Local Council", presaved: true, fdata: "councils", fdata_field: "council", fdata_value: "value" },
        property_type: { multiple: true, input: "select", label: "Types", presaved: true, fdata: "property_type", fdata_field: "property_type" },
        property_type_other: {
          input: "select",
          label: "Category",
          presaved: true,
          fdata: "property_type_other",
          fdata_field: "property_type_other",
          multiple: true,
        },
        status: { input: "select", label: "Status", presaved: true, fdata: "status", fdata_field: "status", multiple: true },
        status_date: { input: "select", label: "Last Updated", presaved: false },
        price: { input: "text", label: "Price", presaved: false },
        city_plan_: { multiple: true, input: "select", label: "Zones", presaved: true, fdata: "zones", fdata_field: "zone" },
        frontages: { input: "range", label: "Frontage (m)", presaved: false },
        length: { input: "range", label: "length (m)", presaved: false },
        area: { input: "range", label: "Area (sqm)", presaved: false },
        fall: { input: "range", label: "Fall", presaved: false },
        hide: { input: "check", label: "Hidden Only", presaved: true, fdata: "hidden", fdata_field: "hide", fdata_value: "value" },
        updated: { input: "check", label: "Exclude Updated", presaved: true, fdata: "updated", fdata_field: "key", fdata_value: "" },
        additional_type: { input: "select", label: "Additional Property Types", presaved: true, fdata: "additional_type", fdata_field: "key", fdata_value: "" },
        property_data: { input: "check", label: "Property Data Availability", presaved: true, fdata: "property_data", fdata_field: "key", fdata_value: "" },
        body_corporate: { input: "check", label: "Exclude Body Corporate", presaved: true, fdata: "body_corporate", fdata_field: "key", fdata_value: "" },
        lot_format: { input: "select", label: "Lot Format", presaved: true, fdata: "lot_format", fdata_field: "lot_format", fdata_value: "" },
        property_t: { input: "select", label: "Type", presaved: true, fdata: "property_t", fdata_field: "property_t", fdata_value: "" },
        tenure_des: { input: "select", label: "Tenure", presaved: true, fdata: "tenure_des", fdata_field: "tenure_des", fdata_value: "" },
      },

      // active filters
      filters: [
        { column: "address", condition: "contains", value: "" },
        { column: "state", condition: "contains", value: [] },
        { column: "council", condition: "contains", value: [] },
        { column: "postal_code", condition: "in", value: [] }, // match complete value t column value
        { column: "suburb", condition: "like_any", value: [] }, //match complete value + case insensitive
        { column: "property_type", condition: "contains", value: [] },
        { column: "property_type_other", condition: "contains", value: [] },
        { column: "city_plan_", condition: "contains", value: [] },
        { column: "status", condition: "contains", value: [] },
        { column: "status_date", condition: "contains", value: [] },
        { column: "area", condition: "range", value: [null, null] },
        { column: "frontages", condition: "array_range", value: [null, null] }, //search within postgres array => any value within range
        { column: "fall", condition: "range", value: [null, null] },
        { column: "hide", condition: "equal", value: null },
      ],

      not_filters: [
        { column: "address", condition: "contains", value: "" },
        { column: "suburb", condition: "like_any", value: [] },
        { column: "property_type", condition: "contains", value: [] },
        { column: "lot_format", condition: "contains", value: "" },
        { column: "property_t", condition: "contains", value: "" },
        { column: "tenure_des", condition: "contains", value: "" },
        { column: "additional_type", condition: "predefined", value: "" },
        { column: "updated", condition: "predefined", value: null },
        { column: "property_data", condition: "predefined", value: null },
        { column: "body_corporate", condition: "predefined", value: null },
        // "not_equal_to",
        // "does_not_contain",
        // "not_in_range",
      ],
      show_not_filters: false,
      selected_filter_length: 0,
      selected_not_filter_length: 0,

      // sidebar fields
      sidebar: false,
      saved_filters: [],

      // load filers section
      selected_filter: null,

      // hide filters
      local_hide: null,
      permissions: null,
    };
  },
  computed: {
    hide_filters() {
      return this.local_hide && this.parent_hide;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(newval) {
        let filters = this.sanitize_filters(newval);
        this.selected_filter_length = filters.length;
        this.$emit("basic-filters-changed", filters);
      },
    },
    not_filters: {
      deep: true,
      handler(newval) {
        let filters = this.sanitize_filters(newval);
        this.selected_not_filter_length = filters.length;
        this.$emit("basic-not-filters-changed", filters);
      },
    },
  },
  async mounted() {
    try {
      for (let [key, o] of Object.entries(this.column_definitions)) if (key == "suburb") o.fdata = this.suburb_set_to_use;
      for (let [key, o] of Object.entries(this.column_definitions)) if (key == "postal_code") o.fdata = "bcc_postcodes";

      await this.initialize();
      // console.log("filters: ", this.filters);
      // console.log("not filters: ", this.not_filters);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initialize() {
      try {
        await this.get_filter_data();
        this.permissions = await this.$store.dispatch("get_permissions");

        this.$emit("basic-filters-changed", this.sanitize_filters(this.filters));
        this.$emit("basic-not-filters-changed", this.sanitize_filters(this.not_filters));
        this.local_hide = this.parent_hide;
      } catch (error) {
        console.log(error);
      }
    },
    sanitize_filters(fs) {
      try {
        if (!fs || !fs.length) return [];
        let filters = fs.filter((f) => f.column && (typeof f.value == "boolean" || (f.value && f.value.length)));
        filters = filters.filter((f) => (["array_range", "range"].includes(f.condition) ? parseInt(f.value[0]) || parseInt(f.value[1]) : true));
        return filters;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async get_filter_data() {
      try {
        let yesterday = moment().subtract(2, "days").format("YYYY-MM-DD");
        let filter_data = localStorage.getItem(this.local_storage_keyname);
        filter_data = JSON.parse(filter_data);

        //   checking if valid filters exist in local storage & not 2x days old
        if (filter_data && filter_data.date > yesterday) this.filter_data = filter_data;
        else {
          //   fetching fresh filter data if not found in local storage || 1x day old
          let res = await this.$axios.get(`${this.$API_BASE_LISTINGS}/getlistingsfilterdata`, { filters: [], options: this.options });
          if (!res.data.success) throw new Error("Could not fetch filter data - check your internet connection");
          this.filter_data = res.data.filter_data;
          // setting filter data for component & local storage
          localStorage.setItem(this.local_storage_keyname, JSON.stringify(this.filter_data));
        }
        // this.showToast("info", "Filter Data Updated");
      } catch (error) {
        console.log(error);
        localStorage.removeItem(this.local_storage_keyname);
        this.showToast("danger", "Error While Updating Filter Data");
      }
    },
    async refresh_filters() {
      try {
        this.showToast("info", "Updating Filter Data...");
        localStorage.removeItem(this.local_storage_keyname);
        await this.get_filter_data();
      } catch (error) {
        console.log(error);
      }
    },
    set_saved_filters(saved_filters) {
      this.saved_filters = saved_filters;
    },
    search() {
      let filters = this.filters.map((f) => {
        return { column: f.column, condition: f.condition, value: f.value };
      });

      window.gtag("event", "filters", {
        event_category: "Filters",
        event_label: "filters",
        value: JSON.stringify(filters),
      });

      filters = filters.filter((f) => f.column && (typeof f.value == "boolean" || (f.value && f.value.length)));
      this.$emit("basic-filters-search", filters);
    },
    clear() {
      [this.filters, this.not_filters].forEach((a) => {
        a.forEach((f) => {
          // handling condition: array && Strings
          if (["contains", "like_any", "in"].includes(f.condition)) {
            if (Array.isArray(f.value)) f.value = [];
            else f.value = "";
          }
          // handling ranges in format [v,v]
          else if (["array_range", "range"].includes(f.condition)) f.value = [null, null];
          // handling predefined values
          else if (["equal", "predefined"].includes(f.condition)) f.value = null;
        });
      });
      this.selected_filter = null;
      // this.showToast("dark", "Filters Cleared");
    },
    set_filter_values({ filters, not_filters }) {
      this.clear();
      // map each incoming filter individually (backward compatibility with field "length")
      filters.forEach((f, i, fs) => {
        let index = this.filters.findIndex((cf) => cf.column == f.column);
        if (index != -1) this.filters[index].value = typeof f.value == "object" ? Object.assign([], f.value) : f.value;
      });

      not_filters.forEach((f, i, fs) => {
        let index = this.not_filters.findIndex((cf) => cf.column == f.column);
        console.log("index: ", index);
        if (index != -1) this.not_filters[index].value = typeof f.value == "object" ? Object.assign([], f.value) : f.value;
      });

      // this.filters = Object.assign([], filters);
    },
    set_filter_values_select(filter) {
      this.set_filter_values(filter.value);
      this.selected_filter = filter;
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.w-30 {
  width: 30% !important;
}

.w-45 {
  width: 45% !important;
}

.capitalize-text {
  text-transform: capitalize;
}

// ::v-deep .load_filter_select,
// ::v-deep .load_filter_select .vs__dropdown-toggle {
//   color: rgb(69, 69, 124);
//   cursor: pointer !important;
//   border: 1px solid blue !important;
//   color: green;
// }
// ::v-deep .load_filter_select:hover,
// ::v-deep .load_filter_select .vs__dropdown-toggle:hover {
//   background-color: blue;
// }
.load-filters-selected {
  white-space: nowrap;
  overflow: hidden;
  // text-overflow: ellipsis;
  max-width: 10ch;
}
.top-border {
  border-top: 1px solid rgb(214, 215, 233);
}
::v-deep #exclude-filters-overlay-background .position-absolute .spinner-border {
  display: none !important;
}
</style>
