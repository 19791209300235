<template>
  <div>
    <!-- <b-button variant="flat-primary" @click="initialize()">
      <feather-icon icon="RefreshCwIcon" class="mr-25" />
      <span class="align-middle">Refresh</span>
    </b-button> -->

    <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
      <b-row>
        <b-col cols="12">
          <l-map
            ref="map"
            style="height: 500px; z-index: 1"
            :zoom="zoom"
            :center="center"
            :max-bounds="max_bounds"
            :max-zoom="max_zoom"
            :min-zoom="min_zoom"
            @update:zoom="zoomUpdated"
            @update:center="centerUpdated"
            @update:bounds="boundsUpdated"
          >
            <l-tile-layer :url="url" :attribution="attribution" :options="{ maxZoom: max_zoom }"></l-tile-layer>
            <template v-for="(p, i) in local_properties">
              <template v-if="p && p.geom">
                <template v-if="zoom < 18">
                  <l-marker :key="'pm' + i" :lat-lng="p.latlng" @click="property_clicked(p)">
                    <l-tooltip>{{ get_property_address(p) }}</l-tooltip>
                  </l-marker>
                </template>
                <template v-if="zoom >= 18">
                  <l-geo-json v-if="p && p.geom" :key="'polygon' + i" :geojson="p.geom" @click="property_clicked(p)"></l-geo-json>
                </template>
              </template>
            </template>
            <v-marker-cluster v-if="local_listings && local_listings.length" :options="clusterOptions">
              <template v-for="(l, i) in local_listings">
                <l-marker v-if="l && l.lat" :key="'marker' + i" :lat-lng="get_lat_lngs(l)" @click="listing_clicked(l)">
                  <!-- on click -->
                  <!-- <l-popup :content="l.address"></l-popup> -->
                  <!-- on hover -->
                  <l-tooltip>{{ l.address }}</l-tooltip>
                </l-marker>
              </template>
            </v-marker-cluster>
          </l-map>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { featureGroup } from "leaflet";
import { LMap, LTileLayer, LMarker, LPolygon, LGeoJson, LPopup, LTooltip, LControl } from "vue2-leaflet";
import Vue2LeafletMarkercluster from "vue2-leaflet-markercluster";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BFormInput,
  BBadge,
  BOverlay,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  name: "ListingsMap",
  components: {
    //   bcomponents
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BFormInput,
    BBadge,
    vSelect,
    BOverlay,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,

    // map components
    LMap,
    LTileLayer,
    LMarker,
    LPolygon,
    LGeoJson,
    "v-marker-cluster": Vue2LeafletMarkercluster,
    LPopup,
    LTooltip,
    LControl,
  },
  props: ["parent_busy", "listings", "properties"],
  data() {
    return {
      // basic map attributes
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
      aus_center: [-25.2744, 133.7751],
      brisbane_center: [-27.4705, 153.026],
      markerLatLng: [-27.4705, 153.026],
      max_bounds: [
        [-4.127285323245357, 185.71289062500003],
        [-43.26120612479978, 82.00195312500001],
      ],
      initial_zoom: 4,
      initial_center: [-27.4705, 153.026],
      max_zoom: 19,
      min_zoom: 5,
      layer_zoom: 9,
      zoom: 4,
      center: [-27.4705, 153.026],
      bounds: null,
      // crs: CRS.EPSG4326,

      // Feature attributes
      // properties: [],
      busy: false,
      geojson: null,
      geoJsonLoaded: false,
      clusterOptions: {
        disableClusteringAtZoom: 11,
        chunkedLoading: false,
      },
      geoJsonOptions: {
        // style: (feature) => {
        //   if (feature.properties.OWNER === "GWI") {
        //     return { color: "#0c38dd" };
        //   } else if (feature.properties.OWNER === "Zayo") {
        //     return { color: "#ff6e00" };
        //   } else if (feature.properties.OWNER === "XO") {
        //     return { color: "#8c08dd" };
        //   } else {
        //     return { color: "#000000" };
        //   }
        // },
        // onEachFeature: (feature, layer) => {
        //   if (feature.properties.OWNER) {
        //     layer.bindPopup("Fiber Cable Owner: " + feature.properties.OWNER);
        //   } else {
        //     layer.bindPopup("Lit Building Address: " + feature.properties.ADDRESS);
        //   }
        // },
      },

      // functional fields
    };
  },
  computed: {
    loading() {
      return this.busy || this.parent_busy;
    },
    local_listings() {
      return this.listings && this.listings.length ? this.listings : [];
    },
    local_properties() {
      return this.properties && this.properties.length ? this.properties : [];
    },
  },
  watch: {
    // listings: {
    //   deep: true,
    //   handler(nv) {
    //     console.log(nv);
    //   },
    // },
    async loading(nv) {
      if (!nv) this.center_on_bounds();
    },
    // local_properties: {
    //   deep: true,
    //   handler(newval) {
    //     // get center of geom from geom
    //     // set as a separate property of "property"
    //     // create markers on basis of latlngs
    //     // when zoom(1-18) = markers, zoom(18-19) = polygons
    //     // should we precalculate that?
    //   },
    // },
  },

  async mounted() {
    try {
      await this.initialize();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initialize() {
      try {
        this.center = Object.assign([], this.initial_center);
        this.zoom = this.initial_zoom;
      } catch (error) {
        console.log(error);
      }
    },
    center_on_bounds() {
      if (!this.$refs.map) return;
      let group = new featureGroup();
      this.$refs.map.mapObject.eachLayer(function (layer) {
        if (layer.feature != undefined) group.addLayer(layer);
      });
      let bounds = [];
      bounds = group.getBounds();
      if (Object.keys(bounds).length) {
        this.$refs.map.mapObject.fitBounds(bounds, { padding: [20, 20] });
      } else {
        // let ls = this.listings.filter((l) => l.lat);
        // bounds = ls.map((l) => [l.lat, l.lon]);
        // this.$refs.map.mapObject.fitBounds(bounds);
        // for bcclistings map only
        this.center = Object.assign([], this.initial_center);
        this.zoom = this.initial_zoom;
      }
    },

    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    async boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    listing_clicked(listing) {
      this.$emit("listing_clicked", listing);
    },
    listing_dbclicked(e) {
      // console.log(e);
      this.showToast("info", "Info", "Listings redirection under construction");
    },
    get_lat_lngs(l) {
      if (l.lat && l.lon) return [l.lat, l.lon];
      else if (l.domain_data && Obkect.keys(l.domain_data).length) return [l.domain_data.addressCoordinate.lat, l.domain_data.addressCoordinate.lon];
      return null;
    },
    get_property_address(p) {
      let a = "";
      // street address
      if (p.unit_numbe) a += p.unit_numbe + "/";
      if (p.house_numb) a += p.house_numb;
      if (p.house_nu_1) a += p.house_nu_1;
      if (p.corridor_n) a += " " + p.corridor_n;
      if (p.corridor_s) a += " " + p.corridor_s;
      // area address
      if (p.suburb) a += ", " + p.suburb;
      a += ", QLD";
      if (p.postcode) a += ", " + p.postcode;

      return a;
    },
    property_clicked(property) {
      this.$emit("property_clicked", property);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "@core/scss/vue/libs/vue-select.scss";
/* .map-card {
  background-color: blue;
  color: red;
} */

.map-select {
  background-color: white;
}
</style>
